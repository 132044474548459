.radar_graph,
.radar_graph_table {
    height: 500px !important;
}

.radar_graph_table {
    overflow-y: auto;
}

.BarLine_section .ama.tabs-container .nav-tabs,
.topTen_section .ama.tabs-container .nav-tabs li,
.modal_container .ama.tabs-container .nav-tabs li {
    line-height: normal;
}

.barLineContainer {
    width: 100%;
    height: 500px;
    overflow-x: auto;
}

.website .table tbody tr:hover {
    background-color: transparent !important;
}

.best_practises .light_tables .sorting_table thead tr {
    background-color: var(--ama-color-background-light-lighter) !important;
}

.best_practises .light_tables .sorting_table thead tr th {
    color: var(--ama-color-grey-900) !important;
}

.best_practises .light_tables .sorting_table tbody tr td:last-child {
    width: 200px !important;
}

.good_bad ul {
    margin-bottom: 0px !important;
    background-color: transparent !important;
}

.good_bad .nav-tabs .nav-link.active {
    background-color: transparent !important;
}

.good_bad ul li {
    font-weight: 700;
    padding: 0px;
    font-size: 22px;
    line-height: 48px;
}

.topTen_section ul {
    background-color: var(--ama-color-background-light-darker) !important;
    margin-bottom: 10px !important;
}

.topTen_section .nav-tabs .nav-link.active {
    background-color: var(--ama-color-background-light-darker) !important;
}

.topTen_section ul li {
    font-size: 16px;
}

.modal_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.close_modal span,
.close_modal i {
    font-size: 32px !important;
}

.ama.tabs-container textarea {
    height: 300px;
}

.website_modal {
    position: absolute;
    top: 5%;
    left: 5%;
    max-width: 90vw;
    max-height: 90vh;
    width: 90vw;
    height: 90vh;
    background-color: var(--ama-color-background-white);
}

.website_overlay_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(228, 228, 228, 0.7);
}

.second_website_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--ama-color-background-white);
    max-width: 90vw;
    max-height: 90vh;
    width: 700px;
    min-height: 350px;
}

.results_website_modal {
    position: absolute;
    top: 5%;
    left: 5%;
    max-width: 90vw;
    max-height: 90vh;
    width: 90vw;
    height: 90vh;
    background-color: var(--ama-color-background-white);
}

.input-group .btn {
    height: 54px;
    border: 2px solid var(--ama-color-brand-primary) !important;
    background-color: var(--ama-color-brand-primary);
    color: var(--ama-color-background-white);
    font-weight: 400;
    font-size: 1em;
    font-family: var(--font-lato);
    border-radius: 27px;
    border-top-right-radius: 27px !important;
    border-bottom-right-radius: 27px !important;
    margin-right: 10px;
    position: absolute;
    z-index: 10;
    min-width: 160px;
}

.input-group .btn:hover {
    background-color: var(--ama-color-background-white);
    color: var(--ama-color-brand-primary);
}

.input-group input {
    height: 54px;
}

/* input[type=file].form-control::file-selector-button {
    display: none;
} */

input[type=file] {
    margin-bottom: 0px;
    padding-left: 0px;
    margin-left: 30px !important;
    border: 2px solid var(--ama-color-brand-primary) !important;
    border-radius: 0;
}

.text_link {
    cursor: pointer;
}

.results_table_container {
    position: relative;
    overflow: auto;
}

.results_subtitle {
    background-color: var(--ama-color-grey-100);
}

.pages_table .table tbody td {
    padding: 0rem !important;
}

/* MOBILE */
@media (min-width: 320px) and (max-width: 480px) {

    .barLineContainer {
        height: 500px;
        width: 800px;
    }

    .overflow {
        overflow-x: auto;
    }
    
    .main_dark .good_bad .nav-tabs .nav-link.active,
    .main_dark .tabs_section .nav-tabs .nav-link.active {
        background-color: var(--ama-color-text-blue) !important;
        color: var(--ama-color-text-gray) !important;
        border: 0px !important
    }

    .pages_container,
    .pages_actions {
        flex-direction: column !important;
        margin-bottom: 10px;
    }

    .best_practises .table {
        width: 700px !important;
    }

    .second_website_modal {
        top: 25vh;
        left: 5vh;
        right: 5vh;
        bottom: 25vh;
    }

    .second_website_modal_dark {
        top: 25vh;
        left: 5vh;
        right: 5vh;
        bottom: 25vh;
    }

    .modal_container .close_modal span {
        display: none;
    }

    .crawl_actions {
        flex-direction: column !important;
    }

    .ama.tabs-container textarea {
        height: 200px;
    }
}

/* DARK MODE */
.main_dark .good_bad .nav-tabs,
.main_dark .tabs_section .nav-tabs,
.website_modal_dark .modal_container .ama.tabs-container .nav-tabs {
    border-color: var(--ama-color-background-dark-darker) !important;
    background-color: var(--ama-color-background-dark-darker) !important;
}

.main_dark .good_bad .nav-tabs .nav-link,
.main_dark .tabs_section .nav-tabs .nav-link,
.website_modal_dark .modal_container .ama.tabs-container .nav-tabs .nav-link {
    color: var(--ama-color-text-blue) !important;
    background-color: var(--ama-color-background-dark-darker) !important;
}

.main_dark .good_bad .nav-tabs .nav-link.active,
.main_dark .tabs_section .nav-tabs .nav-link.active,
.website_modal_dark .modal_container .ama.tabs-container .nav-tabs .nav-link.active {
    border-bottom: 5px solid var(--ama-color-text-blue);
}

.website_dark .link_breadcrumb_container .logout {
    color: var(--ama-color-text-blue) !important;
}

.website_dark section {
    background-color: var(--ama-color-background-dark-lighter) !important;
    color: var(--ama-color-background-white);
}

.website_dark h1 {
    color: var(--ama-color-background-white);
}

.website_dark .sorting_table thead tr,
.website_dark .best_practises .light_tables .sorting_table thead tr {
    background-color: var(--ama-color-background-dark-darker) !important;
}

.website_dark .best_practises .light_tables .sorting_table thead tr th {
    color: var(--ama-color-background-white) !important;
}

.website_dark .sorting_table tbody tr td mark {
    color: var(--ama-color-background-white) !important;
    background-color: var(--ama-color-background-dark-lighter) !important;
    font-weight: bold;
}

.website_modal_dark {
    position: absolute;
    top: 5%;
    left: 5%;
    max-width: 90vw;
    max-height: 90vh;
    width: 90vw;
    height: 90vh;
    background-color: var(--ama-color-background-dark-lighter);
    color: var(--ama-color-background-white);
}

.second_website_modal_dark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--ama-color-background-dark-lighter);
    color: var(--ama-color-background-white);
    max-width: 80vw;
    max-height: 80vh;
    width: 700px;
    min-height: 350px;
}

.results_website_modal_dark {
    position: absolute;
    top: 5%;
    left: 5%;
    max-width: 90vw;
    max-height: 90vh;
    width: 90vw;
    height: 90vh;
    background-color: var(--ama-color-background-dark-lighter);
    color: var(--ama-color-background-white);
}

.website_overlay_modal_dark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(228, 228, 228, 0.2);
}


.website_dark input[type="file"].form-control {
    background-color: var(--ama-color-background-dark-lighter);
    color: var(--ama-color-text-blue);
    border-color: var(--ama-color-text-blue) !important;
}

.website_dark .input-group .btn {
    background-color: var(--ama-color-text-blue);
    color: var(--ama-color-text-gray);
    border-color: var(--ama-color-text-blue) !important;
}

.website_dark .input-group .btn:hover {
    background-color: var(--ama-color-background-dark-lighter);
    color: var(--ama-color-text-blue);
    border-color: var(--ama-color-text-blue) !important;
}

.website_dark .text_link {
    color: var(--ama-color-text-blue);
}

.website_dark .ama.gauge text {
    fill: var(--ama-color-background-white);
}