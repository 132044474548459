.main .link_breadcrumb_container {
  padding: 3rem 0px 2rem 0px;
}

.logo {
  margin-bottom: 0px !important;
}

/* DARK */

.main_dark {
  background-color: var(--ama-color-background-dark-darker) !important;
}