.login_container {
    width: 60%;
}

.login .submit i {
    transform: rotate(90deg);
}

.right_container i {
    font-size: 250px;
}

.gov_logo {
    fill: var(--ama-color-background-white);
}

.login button:hover .gov_logo {
    fill: var(--ama-color-brand-primary);
}

.error {
    color: var(--ama-color-system-error)
}

@media (min-width: 320px) and (max-width: 480px) {
    .icon_mobile {
        display: none !important;
    }

    .login_container {
        width: auto;
    }
}

/* Dark Theme */
.home_dark section {
    background-color: var(--ama-color-background-dark-lighter) !important;
    color: var(--ama-color-background-white);
}

.home_dark .right_container i,
.home_dark .login button:hover i {
    color: var(--ama-color-text-blue);
}

.home_dark .login button i {
    color: var(--ama-color-text-gray);
}

.home_dark .gov_logo {
    fill: var(--ama-color-text-gray);
}

.home_dark .login button:hover .gov_logo {
    fill: var(--ama-color-text-blue);
}

.home_dark button:focus {
    border: 3px solid var(--ama-color-yellow-400) !important;
}