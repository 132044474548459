html, body {
    margin: 0;
    height: 100%;
}

div#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.observatorio {
    color: var(--ama-color-background-white);
    background-color: var(--ama-color-brand-primary);
    height: fit-content;
    width: fit-content;
    text-align: center;
}

.container {
    width: 87% !important;
    max-width: none !important;
}

.main {
    height: auto;
    background-color: var(--ama-color-background-light-darker);
    position: relative;
    padding-bottom: 4rem;
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.section_container {
    width: 90%;
}

.main_dark .observatorio {
    color: var(--ama-color-text-gray);
    background-color: var(--ama-color-text-blue);
}

.link_breadcrumb_container .breadcrumb .breadcrumb-item:not(:first-child) {
    background-image: url("/public/img/chevron-right-dark.svg");
}

.link_breadcrumb_container .breadcrumbs.dark .breadcrumb .breadcrumb-item:not(:first-child) {
    background-image: url("/public/img/chevron-right-light.svg");
}

.link_breadcrumb_container nav ol,
.link_breadcrumb_container nav .breadcrumb {
    margin-bottom: 0px;
}

.link_breadcrumb_container .logout {
    border: 2px solid transparent !important;
}

.link_breadcrumb_container .logout span {
    text-decoration: underline !important;
}

.link_breadcrumb_container .logout i {
    margin-top: 5px;
    font-size: 22px;
}

.link_breadcrumb_container .logout:focus {
    color: var(--ama-color-brand-primary);
    border: 3px solid var(--ama-color-yellow-400) !important;
}

.link_breadcrumb_container .logout:hover {
    color: var(--ama-color-brand-primary);
}


@media (min-width: 320px) and (max-width: 480px) {
    .container {
        width: 95% !important;
    }
}